.profile-menu {
 display: flex;
 padding: 25px 20px 5px;
 min-height: 55px;
 .profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 2px white;
  object-fit: cover;
  margin-top: 5px;
 }
 .profile-info {
  color: #ffffff;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 38px;
//   display: none;
  .profile-name {
   font-size: 18px;
   font-weight: 600;
   margin-bottom: 7px;
   text-transform: capitalize;
  }
  .profile-position {
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 0px;
   text-transform: capitalize;

  }
 }
}

.notification-menu {
 position: absolute;
 min-height: 100px;
 min-width: 200px;
 width: max-content;
 background-color: white;
 border-radius: 8px;
 box-shadow: 0px 8px 32px -8px rgba(0, 0, 0, 0.25);
 color: #242726;
 left: 75px;
 top: 8px;
 padding: 14px 0px;
 z-index: 999;
 .notification-detail {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
 }
 .notification-info {
  margin-top: 0;
  margin-bottom: 11px;
  color: #242726;
  font-size: 14px;
 }
 .notification-date {
  font-size: 12px;
  margin: 0;
 }
}

.notification-indikator {
 width: 10px;
 height: 10px;
 background-color: white;
 border-radius: 50%;
}

.notification-active {
 position: absolute;
 left: 45px;
 top: 10px;
}

.notification-title {
 font-size: 18px;
 text-transform: capitalize;
}

.notification-subtitle {
 font-size: 14px;
 margin-top: 10px;
 text-transform: capitalize;
}

.notification-menu .notification-open,
.notification-menu .notification-item {
 padding: 10px 30px;
 color: #242726;
}
