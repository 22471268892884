.table td, .table th {
    background-color: #FFFFFF !important;
    padding: 16px !important;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    font-size: 14px !important;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6; 
}

