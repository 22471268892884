@import "../../assets/styles/ui-kit/variables";

.form-group .form-label {
 font-size: 14px;
 font-family: "PlusJakartaSans-Bold";
 margin-bottom: 12px;
}

.form-control {
 background: #f5f6fa 0% 0% no-repeat padding-box;
 border-radius: 8px;
 opacity: 1;
 height: 48px;
 text-align: left;
 font: normal normal normal 14px;
 letter-spacing: 0px;
 color: #222d17;
 padding-left: 15px 20px;
}

.end-icon {
 right: 10px;
}

.DateRangePicker {
 margin-bottom: 10px;
 padding: 0;
 position: absolute;
 -webkit-user-select: none;
 user-select: none;
 z-index: 1 !important;
 background: white;
 border: solid 1px #0000001a;
 padding: 8px 3px;
 border-radius: 5px;
 left: 0px;
}

.DateRangePicker__CalendarSelection {
 background-color: #21bad5 !important;
 border: 1px solid #00abbd !important;
 bottom: 5px;
 left: 0;
 position: absolute !important;
 right: 0;
 top: 5px;
}

.input-wrapper {
 position: relative;
}

.right-icon-input {
 position: absolute;
 right: 15px;
 top: 10px;
 color: inherit;
}

.form-group .input-wrapper .form-control::placeholder {
 font-size: 14px !important;
}

input:disabled,
textarea:disabled {
 background-color: #e9ecef !important;
 color: #7c8798 !important;
}

.form-check-inline {
 display: -ms-inline-flexbox;
 display: inline-flex;
 -ms-flex-align: center;
 align-items: center;
 padding-left: 0;
 margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
 position: static;
 width: 24px;
 height: 24px;
 margin-top: 0;
 margin-right: 13px;
 margin-left: 0;
}

.form-check-label {
 font-size: 14px !important;
}

/* The container-checkbox */
.container-checkbox {
 display: block;
 position: relative;
 padding-left: 35px;
 margin-bottom: 12px;
 cursor: pointer;
 font-size: 22px;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
 position: absolute;
 opacity: 0;
 cursor: pointer;
 height: 0;
 width: 0;
}

/* Create a custom checkbox */
.checkmark {
 position: absolute;
 top: 0;
 left: 0;
 height: 25px;
 width: 25px;
 background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
 background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
 background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
 content: "";
 position: absolute;
 display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
 display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
 left: 9px;
 top: 5px;
 width: 5px;
 height: 10px;
 border: solid white;
 border-width: 0 3px 3px 0;
}

.middle {
 width: 100%;
 text-align: center;
 h1 {
  color: #ffff;
 }
 input[type="radio"] {
  display: none;
  &:checked {
   + .box {
    background-color: #f3f3f8;
    span {
     color: #000;
    }
   }
  }
 }
 .box {
  width: 64px;
  height: 48px;
  background-color: #fff;
  transition: all 250ms ease;
  will-change: transition;
  border: 1px solid #e5e5f0;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-weight: 900;
  &:active {
   transform: translateY(10px);
  }
  span {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   transition: all 300ms ease;
   font-size: 18px;
   user-select: none;
   color: #000;
  }
 }

 .radio-check {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
 }

 .radio-close {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
 }
}
