@import "./ui-kit/_variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: url("../fonts/PlusJakartaSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url("../fonts/PlusJakartaSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-ExtraBold";
  src: url("../fonts/PlusJakartaSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url("../fonts/PlusJakartaSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: url("../fonts/PlusJakartaSans-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "PlusJakartaSans-Regular", "Open Sans", sans-serif;
}

.text-extra-bold {
  font-family: "PlusJakartaSans-ExtraBold";
}

.text-bold {
  font-family: "PlusJakartaSans-Bold";
}

.text-medium {
  font-family: "PlusJakartaSans-Medium";
}

.text-light {
  font-weight: 300;
}

.text-semi-bold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-extra-bold {
  font-weight: 800;
}

.super-title {
  font-size: 24px;
 }
 
 .title {
  font-size: 18px;
 }
 
 .subtitle {
  font-size: 14px;
  opacity: 0.48;
 }
 
 .horizontal-line {
  border-bottom: solid 1px #ffffff4a;
  width: 50%;
  margin-left: 25%;
 }
 
 .show {
  display: block;
 }
 
 .hide {
  display: none;
 }

// Scrollbar
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c4c4c4;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #c4c4c4;
}

// Empty State
.empty-state {
  text-align: center;
  padding-top: 25%;
}
.empty-state-caption {
  margin-top: 20px;
}

// Sidebar
.nav-container {
  position: fixed;
  width: 80px;
  top: 0;
  height: 100vh;
  z-index: 100;
  background-color: $base-color-primary-blue;
  transition: width 0.5s ease;
  cursor: pointer;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.4);
  &:hover {
    width: 329px;
  }
}

.nav-ul,
.notification-ul {
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  padding-left: 1rem;
  &:first-child {
    padding-top: 1.5rem;
  }
}

.nav-items {
  padding: 10px 0px;
  a {
    color: #ffffff80 !important;
    text-decoration: none;
    .sidebar-title {
      font-size: 20px;
      position: relative;
      top: -5px;
      padding-right: 15px;
      transition: all 0.3s ease;
      margin-left: 25px;
      margin-right: 10px;
      white-space: nowrap;
    }
    .sidebar-icon {
      padding: 10px;
      border-radius: 5px;
      width: 28px;
      height: 28px;
      position: relative;
      cursor: pointer;
      display: inline;
    }
  }
  &:hover {
    .sidebar-icon {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  a.active {
    .sidebar-icon {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.main-content {
  padding-left: 80px;
  min-height: 100vh;
}

.nav-ul {
  height: calc(100vh - 180px);
}

.notification-ul {
  border-bottom: 1px solid #ffffff80;
  height: 70px;
}
.profile-menu {
  height: 110px;
}
.profile-menu {
  overflow-y: hidden;
  overflow-x: hidden;
  list-style-type: none;
  padding-left: 1rem;
  padding-top: 2rem;
}

.profile-items {
  padding: 10px 0px;
  a {
    display: flex;
    align-items: center;
    color: #ffffff !important;
    text-decoration: none;
    .profile-info {
      position: relative;
      top: -5px;
      transition: all 0.3s ease;
      margin-left: 25px;
      margin-right: 10px;
      white-space: nowrap;
      .profile-name {
        font-size: 20px;
      }
      .profile-position {
        font-size: 14px;
      }
    }
    .profile-img {
      padding: 10px;
      border-radius: 5px;
      width: 28px;
      height: 28px;
      position: relative;
      cursor: pointer;
      display: inline;
    }
  }
}

.login-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  .content-wrapper {
   width: 55%;
   margin: 0;
   padding: 100px 175px 20px;
   position: relative;

   .logo{
     max-width: 350px;
   }
  }
  .side-login {
   width: 45%;
  //  background-color: $base-color-primary;
   background-image: url('../img/background/login-page.png');
  }
  @media (max-width: 920px) {
   .content-wrapper {
    width: 100%;
    margin: 0;
    padding: 60px 40px;
   }
   .side-login {
    display: none;
   }
  }
 }
//button
.button {
  font-size: 14px;
  border-radius: 5px;
  padding: 12px 22px;
  border: none;
  //display: inline-flex;
  justify-content: center;
 }
 
 .button:hover {
  justify-content: center;
 }
 
 .rounded-button {
  border-radius: 30px;
  font-size: 14px;
 }
 
 .clickable {
  cursor: pointer;
 }
 
 .left-icon {
  margin-right: 13px;
 }
 
 .right-icon {
  margin-left: 13px;
 }
 
 .icon-only {
  margin-left: 13px;
 }
 
 .md {
  min-width: 64px;
  border-radius: 5px;
  height: 40px;
  padding: 0 24px;
  border: none;
  margin-right: 5px;
 }
 .lg {
  min-width: 64px;
  border-radius: 5px;
  height: 48px;
  padding: 0 32px;
  border: none;
  margin-right: 5px;
 }
 .disabled {
  background-color: #dedede !important;
  border-color: #dedede !important;
  box-shadow: none !important;
 }
 .disabled:hover {
  background-color: red !important;
  border-color: #dedede !important;
  box-shadow: none !important;
 }
 .button-default {
  background-color: $base-color-primary-blue !important;
  border-color: $base-color-primary-blue !important;
  color: white;
  .active {
   background-color: $base-color-primary-blue;
   border-color: $base-color-primary-blue;
  }
 }
 .button-default:hover:not(.disabled) {
  background-color: $base-color-primary-blue-hover !important;
  border-color: $base-color-primary-blue-hover !important;
  box-shadow: 0 2px 6px $base-color-primary-blue-hover !important;
 }
 .button-negative {
  background-color: $base-color-danger !important;
  border-color: $base-color-danger !important;
  color: #fff;
  .active {
   background-color: #d53a34 !important;
   border-color: #d53a34 !important;
  }
 }
 .button-negative:hover:not(.disabled) {
  background-color: #d10d0d80 !important;
  border-color: #d10d0d80 !important;
  box-shadow: 0 2px 6px #d10d0d80 !important;
 }
 .button-affirmative {
  background-color: #00cf89 !important;
  border-color: #00cf89 !important;
  color: #fff;
  .active {
   background-color: #00ba7b !important;
   border-color: #00ba7b !important;
  }
 }
 .button-affirmative:hover:not(.disabled) {
  background-color: #00cf8980 !important;
  border-color: #00cf8980 !important;
  box-shadow: 0 2px 6px #00cf8980 !important;
 }
 
 .btn-icon {
  font-size: calc(24em / 14);
 }
 
 .btn-icon-right {
  margin-left: 10px;
 }
 
 .btn-icon-left {
  margin-right: 10px;
 }

 .button-white {
  background-color: white !important;
  border: 1px solid #dedede !important;
  color: black;
  .active {
   background-color: white !important;
   border: 1px solid #dedede !important;
  }
 }
 .button-white:hover:not(.disabled) {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 2px 6px #dedede !important;
 }
//  search

.search-toggle,
.search-toggle:hover,
.search-toggle:focus,
.search-toggle:active {
 background-color: transparent;
 border: none;
 color: #3e3e3e;
 padding-right: 0;
 padding-left: 0;
}

.search-container {
  position: relative;
  margin-right: 20px;
 }
 
 .input-search{
   transition: width .15s;
   width: 10px;
  }
 
 .input-search,
 .input-search:hover,
 .input-search:focus {
   visibility: hidden;
   padding: 11px 20px;
   border: 1px solid #e5e5f0;
   box-sizing: unset;
   background: #f3f3f8;
   border-radius: 32px;
   outline: none !important;
   overflow-x: hidden;
   position: absolute;
   right: 0;
 }
 
 .input-search::placeholder {
  color: #b8b8d6;
  font-size: 14px;
 }
 
 .input-search.show {
   width: 200px;
   visibility: visible;
   margin-top: -20px;
 }
 
 .clickable {
  cursor: pointer;
 }

 .mini-show {
  display: none;
 }

 .mini-hide {
  display: block;
 }

 .menu-container {
  // margin: 20px 20px 20px 100px;
  padding: 0px;
  height: max-content;
  min-height: 100vh;
  @media (max-width: 920px) {
   padding: 0;
  }
 }

 .content-container-side {
  padding: 0px 40px;
  max-width: 100vw;
  @media (max-width: 920px) {
   padding: 40px;
  }
  .overflow {
   overflow-y: none;
   overflow-x: auto;
   margin: 0;
  }
 }
 
 .icon-only {
  margin-left: 13px;
 }

 .menu-title {
  font-size: 24px;
  padding: 50px 40px 36px;
 }
 
.header-title {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: stretch;
  min-height: max-content;
  .menu-title-icon {
   float: left;
   align-content: center;
   align-self: center;
   padding: 5px 20px 0 0;
  }
  .menu-right {
   float: right;
   text-align: right;
   align-self: right;
   padding: 50px 0px 36px;
   .menu-item {
    //display: inline-flex;
   }
  }
}

.desc-title-form {
  padding-left: 2rem;
  padding-right: 2rem;
}

.modal-title {
  width: 100%;
}

.click-in-right {
  float: right;
}

.modal-header {
  padding: 1rem 2rem 0rem 2rem;
  border-bottom: none;
}

.button-batal {
  background: #ffffff;
  border-radius: 24px;
  border: 2px solid #E5E5F0;
  color:  black;
  padding: 5px 30px;
}

.button-simpan{
  background: #1C37BE;
  border-radius: 24px;
  color: #ffffff;
  padding: 5px 30px;
  border: 1px solid #1C37BE;
}

.button-broadcast {
  background: #ffffff;
  border-radius: 24px;
  border: 2px solid #E5E5F0;
  color:  black;
  // width: 250px;    
  padding-bottom: 10px;
  padding-top: 10px;
}

.button-broadcast:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.button-generate-ulang {
  background: $base-color-primary-blue;
  border-radius: 24px;
  border: 2px solid $base-color-primary-blue;
  color:  white;
  width: 160px;    
  padding-bottom: 10px;
  padding-top: 10px;
}

.button-generate-ulang:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 102, 255, 0.1), 0 6px 20px 0 rgba(25, 29, 248, 0.1);
}

.button-filter-city {
  background: #273CA4;
  border-radius: 24px;
  border: 2px solid #273CA4;
  color: white;
  width: 80px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-left: 5px;
}

.button-filter-city:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 102, 255, 0.1), 0 6px 20px 0 rgba(25, 29, 248, 0.1);
}

.icon-modal {
  color: black;
  font-size: 40px;
  margin-top: 5px;
  margin-right: 29px;
}

.primary {
  background-color: $base-color-primary-blue;
  border-color: $base-color-primary-blue;
  color: white;
}
 
.primary:hover {
  color: white;
  background-color: $base-color-primary-blue-hover;
  border-color: $base-color-primary-blue-hover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
 
.primary:focus,
.primary:active {
  background-color: $base-color-primary-blue-hover !important;
  border-color: $base-color-primary-blue-hover !important;
}
 
.secondary {
  background-color: #ffffff;
  border: solid 1px $base-color-secondary;
  color: black;
}
 
.secondary:hover {
  background-color: #ffffff;
  border: solid 1px $base-color-secondary;
  color: #3e3e3e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
 
.secondary:focus,
.secondary:active {
  background-color: $base-color-secondary !important;
  border-color: $base-color-secondary !important;
}
  
//detail promo
.sidebar-promo-col{
  border-right: 5px solid #F3F3F8;
  padding: 0px;
  margin-left: -15px;
} 
.sidebar-promo{
  .header{
    font-size: 24px;
    font-weight: 800;
    padding: 50px 40px 36px;
    text-align: center;
    cursor: pointer;

  }
  .item{
    padding: 20px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    h5{
      font-weight: 800;
    }
  }
  .item.active{
    background-color: #F3F3F8;
  }
  .item:hover{
    background-color: #F3F3F8;
   
  }
}
.box-rounded{
  border: 1px solid #E5E5F0;
box-sizing: border-box;
border-radius: 16px;
margin-bottom: 30px;
}
#table-detail-promo{
  width: 100%;
  tr{
    border-bottom: 1px solid #E5E5F0;
    height: 40px;
    word-break: break-word;
    th{
      padding: 20px;
    }
  }
}

// gallery
.danger {
  color: white;
  background-color: $base-color-danger !important;
}
 
.warning {
  background-color: $base-color-warning !important;
}

.icon-button {
  align-self: center;
  margin-right: 15px;
}

.box-upload{
  height: 100px;
  background-color: #F6F6F6;
  padding: 20px;
  label{
    display: flex;
  }
  .img-preview{
    width: 60px;
    height: 60px;
  }
  .filename{
    margin-left: 20px;
    p{
      margin-bottom: 0.5rem;
    }
  }
}
.warn-text{
  color:red;
}

.error-message{
  font-size: 14px;
}

#table-detail-gallery{
  width: 100%;
  tr{
    border-bottom: 1px solid #E5E5F0;
    height: 40px;
    word-break: break-word;
    th{
      padding: 20px;
      width: 200px;
    }
    td{
      padding: 20px;
    }
  }
}

.sidebar-gallery-col{
  border-right: 5px solid #F3F3F8;
  padding: 0px;
} 

.sidebar-gallery{
  .header{
    font-size: 24px;
    font-weight: 800;
    padding: 50px 40px 36px;
    text-align: center;
    cursor: pointer;
  }
  .item{
    padding: 20px;
    display: flex;
    margin-left: 20px;
    word-break: break-all;
    h5{
      font-weight: 800;
    }
    i {
      margin-right: 20px;
      color: #273CA4;
    }
  }
}

.img-preview-detail {
  height: 300px;
  width: auto;
  max-width: 400px;
  margin-bottom: 10px;
}
.header-generate-result{
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  .title{

  }
  .img-generate-result{
    max-width: 200px;
  }
}
.button-generated-result{
  float: right;
}
.upload-voucher::-webkit-file-upload-button {
  visibility: hidden;
}
.append-upload-voucher{
  height: 100%;
  color: #1C37BE;
  background-color: #fff;
}
// voucher
#table-detail-voucher{
  width: 100%;
  tr{
    border-bottom: 1px solid #E5E5F0;
    height: 40px;
    word-break: break-word;
    th{
      padding: 20px;
      width: 200px;
    }
    td{
      padding: 20px;
    }
  }
}
.space-detail-voucher {
  margin-bottom: 20px;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  background-color: white !important;
}

.dropdown {
  text-align: left ; 
  padding: 10px 20px 10px 20px;
  width: 180px;
}

.dropdown-winner {
  text-align: left ; 
  padding: 7px 20px 7px 20px;
  width: 120px;
}

.add-dinamic-gallery {
  .style-color {
    background-color: #1C37BE;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .icon {
    height: 22px;
    width: 22px;
  }
}

.btn-foto-dinamis {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.dinamic-column {
  margin: 5px 0px;
}

.btn-remove {
  background-color: #FF4E4E;
  border-radius: 50%;
  border: none;
  color: #fff;
  width: 50px;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  padding-bottom: 5px;
}

.disabled-voucher {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}